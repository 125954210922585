<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6><a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> အပေါင် သက်တမ်း ထည့်ရန် <small>(Fields in red are required)</small></h6>
					</div>
					<div class="card-body">
						<form v-on:submit.prevent="onSubmit">
							<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label text-danger">အပေါင်သက်တမ်း နာမည်</label>
								<div class="col-sm-10">
									<input type="text" v-model="itemRequest.name" @blur="validationRule()"
										class="form-control" id="inputName">
									<small class="text-danger">{{validationData.name}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputPeriod" class="col-sm-2 col-form-label text-danger">ကာလ</label>
								<div class="col-sm-10">
									<input type="text" v-model="itemRequest.period" @blur="validationRule()"
										class="form-control" id="inputPeriod">
									<small class="text-danger">{{validationData.period}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputSlug" class="col-sm-2 col-form-label text-danger">ကုဒ်နံပါတ်</label>
								<div class="col-sm-10">
									<input type="text" v-model="itemRequest.slug" @blur="validationRule()"
										class="form-control" id="inputSlug">
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">လုပ်ဆောင်မည်</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'itemCreate',
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				filter: {
					page: "",
                    reverse: "",
                    sort: "",
                    name: "",
				},
				itemRequest: {
					id: "",
					name: "",
					period: "",
					slug: ""
				},
				validationData: {
					name: "",
					period: "",
				}
			}
		},
		watch: {
			async $route(to, from) {
				if (to.params.id) {
					this.itemRequest.id = to.params.id
					await this.itemDetail()
				}
			},
		},
		computed: {},
		methods: {
			...mapActions({
				interestClothUpdateAction: 'interestClothUpdateAction',
				interestClothFieldsAction: 'interestClothFieldsAction',
			}),
			back() {
				this.$router.replace({ path: 'interest-cloth-list', query: { page: this.filter.page, reverse: this.filter.reverse, sort: this.filter.sort, name: this.filter.name} }).catch(()=>{})
			},
			async getFields(){
				let option = {
					fields: {id: this.itemRequest.id}
				}
				await this.interestClothFieldsAction({
					...option
				}).then(res => {
					this.itemRequest.name = res.data.data.name
					this.itemRequest.period = res.data.data.period
					this.itemRequest.slug = res.data.data.slug
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
				let option = {
					id: this.itemRequest.id,
					name: this.itemRequest.name,
					period: this.itemRequest.period,
					slug: this.itemRequest.slug
				}
				await this.interestClothUpdateAction({
					...option
				}).then(res => {
					this.back()
				}).catch(err => this.isLoading = true)
			},
			validationRule() {
				let isValidation = []
				if (this.itemRequest.name == "") {
					this.validationData.name = "အပေါင်သက်တမ်း အမည် ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.name = ""
					isValidation.push(false)
				}
				if (this.itemRequest.period == "") {
					this.validationData.period = "ကာလ ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.period = ""
					isValidation.push(false)
				}
				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
			this.filter.page = this.$route.query.page
            this.filter.reverse = this.$route.query.reverse
            this.filter.sort = this.$route.query.sort
            this.filter.name = this.$route.query.name
			this.itemRequest.id = this.$route.query.id
			this.getFields()
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>